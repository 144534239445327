export function main(
  _vsSource,
  _fbsSource,
  _fsSource,
  _globals,
  _drawInfo,
  _feedbackInfo,
  _init,
  _tick,
) {
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const canvas = document.getElementById("gl");
  let gl = canvas.getContext("webgl");
  canvas.width = sizes.width;
  canvas.height = sizes.height;
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

  // fullscreen quad
  const quadVerts = new Float32Array([
    -1, -1, 1, -1, -1, 1, -1, 1, 1, -1, 1, 1,
  ]);
  const quadBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, quadBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, quadVerts, gl.STATIC_DRAW);

  const programs = initShaderPrograms(gl, _vsSource, _fbsSource, _fsSource);
  const fbInfo = _feedbackInfo(gl, programs.feedbackProgram);
  const drawInfo = _drawInfo(gl, programs.drawProgram);
  let speckle = false;
  function poke(x, y, texture) {
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texSubImage2D
    gl.texSubImage2D(
      gl.TEXTURE_2D,
      0,
      // x offset, y offset, width, height
      x,
      y,
      1,
      1,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      // is supposed to be a typed array
      new Uint8Array([255, 255, 255, 255]),
    );
  }
  canvas.onmousedown = function (event) {
    //console.debug(event);
    console.debug("mousedown", event.offsetX, canvas.height - event.offsetY);

    poke(
      event.offsetX / _globals.pixelDensity,
      (canvas.height - event.offsetY) / _globals.pixelDensity,
      _globals.backTex,
    );
  };
  canvas.onmousemove = function (event) {
    //console.debug(event);
    if (!speckle) {
      poke(
        event.offsetX / _globals.pixelDensity,
        (canvas.height - event.offsetY) / _globals.pixelDensity,
        _globals.backTex,
      );
    }
  };
  canvas.ontouchmove = function (event) {
    event.preventDefault();
    poke(
      event.changedTouches[0].clientX / _globals.pixelDensity,
      (canvas.height - event.changedTouches[0].clientY) / _globals.pixelDensity,
      _globals.backTex,
    );
  };
  window.onkeydown = function (event) {
    if (event.key === "Shift") {
      speckle = true;
    }
  };
  window.onkeyup = function (event) {
    if (event.key === "Shift") {
      speckle = false;
    }
  };
  _init(gl, fbInfo, drawInfo);

  function makeTexture() {
    _globals.backTex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, _globals.backTex);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      sizes.width,
      sizes.height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null,
    );

    _globals.frontTex = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, _globals.frontTex);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      sizes.width,
      sizes.height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null,
    );

    _globals.framebuffer = gl.createFramebuffer();
  }

  let time = 0;

  function render() {
    window.requestAnimationFrame(render);
    time++;
    _tick(gl, fbInfo, drawInfo, time);
  }
  makeTexture();
  render();
}

function initShaderPrograms(gl, vsSource, fbsSource, fsSource) {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
  const feedbackShader = loadShader(gl, gl.FRAGMENT_SHADER, fbsSource);

  const drawProgram = gl.createProgram();
  gl.attachShader(drawProgram, vertexShader);
  gl.attachShader(drawProgram, fragmentShader);
  gl.linkProgram(drawProgram);

  if (!gl.getProgramParameter(drawProgram, gl.LINK_STATUS)) {
    alert(
      "Unable to initialize the shader program: " +
        gl.getProgramInfoLog(drawProgram),
    );
    return null;
  }

  const feedbackProgram = gl.createProgram();
  gl.attachShader(feedbackProgram, vertexShader);
  gl.attachShader(feedbackProgram, feedbackShader);
  gl.linkProgram(feedbackProgram);

  if (!gl.getProgramParameter(feedbackProgram, gl.LINK_STATUS)) {
    alert(
      "Unable to initialize the shader program: " +
        gl.getProgramInfoLog(feedbackProgram),
    );
    return null;
  }

  return { drawProgram, feedbackProgram };
}

function loadShader(gl, type, source) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    alert(
      "An error occurred compiling the shaders: " + gl.getShaderInfoLog(shader),
    );
    gl.deleteShader(shader);
    return null;
  }

  return shader;
}
